import { PathSegment, PathTranslations } from 'service/localization/getPathTranslations';
import { StringService } from 'service/string/stringService';

const removeLeadingSlash = (str: string): string => str.substring(1);

const getHeroBannerProductSubcategoryPageSlug = (
  item: any,
  pathTranslations: PathTranslations,
  parentLink?: string,
) => {
  const categorySlug =
    item?.internalLink?.productCategoryPage?.slug ||
    item?.internalLink?.productSubcategoryPage?.productCategoryPage?.slug;
  const subcategorySlug = item?.internalLink?.productSubcategoryPage?.slug;
  return `${pathTranslations.productsSubCategory}${parentLink ? `${parentLink}/` : ''}${
    categorySlug ? `${categorySlug}/` : ''
  }${subcategorySlug ? `${subcategorySlug}/` : ''}${item?.internalLink.slug}`;
};

const getProductSubcategoryPageSlug = (
  item: any,
  pathTranslations: PathTranslations,
  parentLink?: string,
) => {
  return `${pathTranslations.productsSubCategory}${parentLink ? `${parentLink}/` : ''}${item?.internalLink.slug}`;
};

const getHeroBannerRecipeSubcategoryPageSlug = (
  item: any,
  pathTranslations: PathTranslations,
  parentLink?: string,
) => {
  const categorySlug =
    item?.internalLink?.recipeCategory?.slug ||
    item?.internalLink?.recipeSubcategory?.recipeCategory?.slug;
  const subcategorySlug = item?.internalLink?.recipeSubcategory?.slug;
  return `${pathTranslations?.recipesSubCategory}${parentLink ? `${parentLink}/` : ''}${
    categorySlug ? `${categorySlug}/` : ''
  }${subcategorySlug ? `${subcategorySlug}/` : ''}${item?.internalLink.slug}`;
};

const getRecipeSubcategoryPageSlug = (
  item: any,
  pathTranslations: PathTranslations,
  parentLink?: string,
) =>
  `${pathTranslations.recipesSubCategory}${parentLink ? `${parentLink}/` : ''}${
    item?.internalLink.slug
  }`;

type IGetInternalLinks = {
  item: any;
  pathTranslations: PathTranslations;
  parentLink?: string;
  isHeroBanner?: boolean;
  locale?: string;
};

const getInternalLinks = ({
  item,
  pathTranslations,
  parentLink,
  isHeroBanner,
  locale,
}: IGetInternalLinks) => {
  let LinkUrl;

  if (item?.internalLink && item?.internalLink?.__typename === 'GenericPage') {
    if (item.internalLink.type === 'Generic') {
      LinkUrl = `${pathTranslations?.genericPage}${item?.internalLink?.slug}`;
    } else if (item.internalLink.type) {
      // this should be able to handle About | Product Promotios
      //  | Services | Support | inspiration pages
      LinkUrl = `${
        pathTranslations[StringService.lowerCaseFirstLetter(item.internalLink.type) as PathSegment]
      }${item.internalLink.subPath ? removeLeadingSlash(item.internalLink.subPath) : ''}${
        item.internalLink.slug
      }`;
    }
  } else if (item?.internalLink && item?.internalLink?.__typename === 'ProductSubcategoryPage') {
    LinkUrl = isHeroBanner
      ? getHeroBannerProductSubcategoryPageSlug(item, pathTranslations, parentLink)
      : getProductSubcategoryPageSlug(item, pathTranslations, parentLink);
  } else if (item?.internalLink && item?.internalLink.__typename === 'ProductCategoryPage') {
    LinkUrl = `${pathTranslations?.productsCategory}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeLandingPage') {
    LinkUrl =
      pathTranslations?.recipes.match(/\/([^/]*)\/$/)?.[1] === item?.internalLink.slug
        ? pathTranslations?.recipes
        : `${pathTranslations?.recipes}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'ProductLandingPage') {
    LinkUrl =
      pathTranslations?.products.match(/\/([^/]*)\/$/)?.[1] === item?.internalLink.slug
        ? pathTranslations?.products
        : `${pathTranslations?.products}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeCategory') {
    LinkUrl = `${pathTranslations?.recipesCategory}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeSubcategory') {
    LinkUrl = isHeroBanner
      ? getHeroBannerRecipeSubcategoryPageSlug(item, pathTranslations, parentLink)
      : getRecipeSubcategoryPageSlug(item, pathTranslations, parentLink);
  }

  return locale ? `${locale}/${LinkUrl}` : LinkUrl;
};

export const LinksService = {
  getInternalLinks,
};
